// navigationScroll.js

export default function navigationScroll(selector) {
    function initializeScrolling() {
        const $fixedDiv = $(selector);
        const $legalNoticeDiv = $('.c-notice-legal');
        const initialTop = parseInt($fixedDiv.css('top'), 10) || 0; // Get the current CSS top value
        const legalNotice = $legalNoticeDiv.length ? parseInt($legalNoticeDiv.outerHeight(), 10) || 0 : 0;

        $fixedDiv.css('top', initialTop + legalNotice + 'px');

        let lastScrollTop = 0; // To store the last scroll position

        function scrollHandler() {
            const currentScrollTop = $(window).scrollTop();

            if (currentScrollTop !== lastScrollTop) {
                // Dynamically adjust the top position
                $fixedDiv.css('top', initialTop + legalNotice - currentScrollTop + 'px');

                // Update the last scroll position
                lastScrollTop = currentScrollTop;
            }

            // Use requestAnimationFrame for smoother updates
            requestAnimationFrame(scrollHandler);
        }

        // Start the animation loop
        requestAnimationFrame(scrollHandler);
    }

    // Only initialize on screen widths below 930px
    function checkScreenSize() {
        if (window.innerWidth < 930) {
            initializeScrolling();
        }
    }

    // Run on page load and when resizing the window
    checkScreenSize();
    window.addEventListener('resize', () => {
        if (window.innerWidth < 930) {
            initializeScrolling();
        } else {
            // Stop applying the scroll behavior (optional cleanup logic can go here)
        }
    });
}