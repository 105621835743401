// navigationBurgerMenu.js

export default function navigationBurgerMenu(burgerSelector, menuSelector, closeSelector) {
    const $burger = $(burgerSelector);
    const $menu = $(menuSelector);
    const $close = $(closeSelector);
    const $body = $('body');

    function openMenu() {
        $burger.addClass('js-active');
        $menu.addClass('js-open');
        $body.addClass('js-menu-open'); // Disable scrolling
    }

    function closeMenu() {
        $burger.removeClass('js-active');
        $menu.removeClass('js-open');
        $body.removeClass('js-menu-open'); // Enable scrolling
    }

    // Toggle menu on click
    $burger.on('click', function () {
        if ($menu.hasClass('js-open')) {
            closeMenu();
        } else {
            openMenu();
        }
    });

    // Close menu on close button click
    $close.on('click', closeMenu);

    // Close menu when clicking outside the menu
    $(document).on('click', function (e) {
        if (
            !$burger.is(e.target) &&
            !$menu.is(e.target) &&
            !$menu.has(e.target).length
        ) {
            closeMenu();
        }
    });

    // Close menu on Escape key press
    $(document).on('keydown', function (e) {
        if (e.key === 'Escape') {
            closeMenu();
        }
    });
}