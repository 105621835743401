// Import Styles
import '../sass/styles.scss';

// Import Plugins
import 'bootstrap';
import 'jquery-match-height';

import './slider/post-latest';
import './slider/post-related';
import './slider/poker-featured';
import './slider/poker-article-category';
import './slider/team-pros-posts';

// Import JS Class
import { Helpers } from './helpers/helpers';
import { LoadMore } from './ajax/load-more';
import { LoadMorePSPC } from './ajax/load-more-pspc';

import './helpers/clock';
import './dropdown/table-of-contents';

import navigationBurgerMenu from './helpers/navigationBurgerMenu.js';
import navigationScroll from './helpers/navigationScroll';
import doubleTapToGo from './helpers/doubleTapToGo.js';

(function() {

    const helper = new Helpers();

    // Setup AJAX Load More
    const loadMore = new LoadMore();

    // Setup AJAX Load More PSPC
    const loadMorePSPC = new LoadMorePSPC();

    $(window).on('load', function () {
        // Initialize Navigation Burger Menu
        navigationBurgerMenu('.js-burger-menu', '.js-site-header-side', '.js-site-side-header__close');

        // Initialize Navigation Sub Menu Scrolling
        navigationScroll('.sub-menu');

        // Initialize Navigation Double Tap to Go Functionality
        doubleTapToGo('.menu-item-has-children > a');
    });

    $(document).ready(function() {

        helper.wrapTitles();

        $('.h-match').matchHeight();

        // Categories

        var categoryNavigation = $('.js-posts-categories');

        $('.js-posts-categories-next').on('click', function() {
            var leftPosition = categoryNavigation.scrollLeft();
            categoryNavigation.animate({
                scrollLeft: leftPosition + 200,
            }, 300);
        });

        $('.js-posts-categories-previous').on('click', function() {
            var leftPosition = categoryNavigation.scrollLeft();
            categoryNavigation.animate({
                scrollLeft: leftPosition - 200,
            }, 300);
        });

        // Lightbox

        const lightbox = $('.js-video-lightbox');
        const embedContainer = lightbox.find('.embed-container');

        function openLightbox(e) {
            e.preventDefault();
            const button = $(this);
            const videoId = button.data('video-id');
            const accountId = button.data('account-id') || '2637383244001';
            const locale = button.data('locale') || 'en';

            const videoHtml = `
                <video class="video-js" data-video-id="${videoId}" data-account="${accountId}" data-player="HkGNBnrbiW" data-embed="default" data-application-id="" controls language="${locale}" translate></video>
                <script src="//players.brightcove.net/${accountId}/HkGNBnrbiW_default/index.min.js"></script>
            `;

            embedContainer.html(videoHtml);
            lightbox.addClass('active');
        }

        function closeLightbox(e) {
            e.preventDefault();
            embedContainer.find('video').trigger('pause');
            lightbox.removeClass('active');
            embedContainer.empty();
        }

        $(document).on('click', '.js-video-lightbox-button', openLightbox);
        $(document).on('keypress', '.js-video-lightbox-button', function(e) {
            if (e.key === 'Enter') {
                openLightbox.call(this, e);
            }
        });

        $(document).on('click', '.js-video-lightbox-close', closeLightbox);
        $(document).on('keypress', '.js-video-lightbox-close', function(e) {
            if (e.key === 'Enter') {
                closeLightbox.call(this, e);
            }
        });

        // Scroll Navigation

        $('.c-posts-categories').scroll(function() {

            let scrollLeft, menuWidth;

            scrollLeft = $(this).scrollLeft();
            menuWidth = $(this)[0].scrollWidth;

            if (scrollLeft > 0) {
                $(this).addClass('scrolled');
            } else {
                $(this).removeClass('scrolled');
            }
        });

        // Back to Top

        $(window).on('scroll', function() {
            if ($(this).scrollTop() > 200) {
                $('.js-back-to-top').fadeIn(200);
            } else {
                $('.js-back-to-top').fadeOut(200);
            }
        });

        $('.js-back-to-top').on('click', function() {
            $('html, body').animate({ scrollTop: 0 }, 'slow');
            return false;
        });

        // Accordion 

        $('.js-accordion-title').on('click', function() {
            var content = $(this).next('.js-accordion-content');
            $(this).toggleClass
            content.toggleClass('active');
        });
    });
})();