// doubleTapToGo.js

export default function doubleTapToGo(menuSelector) {
    const mediaQuery = window.matchMedia('(max-width: 930px)');

    function initializeDoubleTap() {
        let previousTarget = null;

        // Add event listener to the menu items
        $(menuSelector).on('click', function (e) {
            // Remove the 'js-open' class from all submenus
            $('.sub-menu').removeClass('js-open');

            if (this === previousTarget) {
                // If this is the second tap, let it proceed
                previousTarget = null;
            } else {
                // Prevent the default click behavior and set the target
                e.preventDefault();
                previousTarget = this;
                $(this).next('.sub-menu').toggleClass('js-open');
            }
        });
    }

    function removeDoubleTap() {
        // Clean up event listeners when above the threshold
        $(menuSelector).off('click');
        $('.sub-menu').removeClass('js-open'); // Ensure submenus are closed
    }

    function setupDoubleTap() {
        if (mediaQuery.matches) {
            initializeDoubleTap();
        } else {
            removeDoubleTap();
        }
    }

    // Set up on initial load
    setupDoubleTap();

    // Listen for screen size changes
    mediaQuery.addEventListener('change', setupDoubleTap);
}